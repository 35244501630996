<template>
  <div class="section-wrapper">
    <b-card class="trade_fire_wrapper">
        <b-row>
            <b-col sm="4">
                <b-form-group
                    label-for="fiscal_year_id"
                    >
                    <template v-slot:label>
                        {{ $t('tradeFairConfig.fiscal_year') }}
                    </template>
                    <b-form-select
                        plain
                        id="fiscal_year_id"
                        :options="fiscalYearList"
                        v-model="search.fiscal_year_id"
                    >
                        <template v-slot:first>
                        <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group
                    label-for="month"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.month') }}
                    </template>
                    <b-form-select
                        plain
                        id="month"
                        :options="monthList"
                        v-model="search.month"
                    >
                        <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group
                    label-for="fair_sector_id"
                    >
                    <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_sector') }}
                    </template>
                    <b-form-select
                        plain
                        id="fair_sector_id"
                        :options="fairSectorList"
                        v-model="search.fair_sector"
                    >
                        <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <!-- <b-col sm="3">
                <b-button size="sm" variant="primary" class="mb-2" @click="searchData">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
            </b-col> -->
        </b-row>
        <itf-loading v-if="!dataLoad" :step="1" />
        <b-row v-else>
          <b-col class="bdr" sm="3">
            <div class="info-card upcoming">
              <div class="content">
                <p>{{ $t('tradeFairDashboard.upcoming_fair') }}</p>
                <h3>{{ $n(dashboardData.upcoming_fair, { useGrouping: false }) }}</h3>
              </div>
              <img src="@/assets/images/amico.png" class="img-fluid" alt="image">
            </div>
          </b-col>
          <b-col class="bdr mt-3 mt-sm-0" sm="3">
            <div class="info-card total">
              <div class="content">
                <p>{{ $t('eBizDashboard.total_circular') }}</p>
                <h3>{{ $n(dashboardData.total_circular, { useGrouping: false }) }}</h3>
              </div>
              <img src="@/assets/images/folder-bro.png" class="img-fluid" alt="image">
            </div>
          </b-col>
          <b-col class="bdr" sm="3">
            <div class="info-card atended">
              <div class="content">
                <p>{{ $t('tradeFairDashboard.attended_fair') }}</p>
                <h3>{{ $n(dashboardData.attended_fair, { useGrouping: false }) }}</h3>
              </div>
              <img src="@/assets/images/stall.png" class="img-fluid" alt="image">
            </div>
          </b-col>
          <b-col class="bdr mt-3 mt-sm-0" sm="3">
            <div class="info-card remaining">
              <div class="content">
                <p>{{ $t('tradeFairDashboard.remaining_fair') }}</p>
                <h3>{{ $n(dashboardData.remaining_fair, { useGrouping: false }) }}</h3>
              </div>
              <img src="@/assets/images/rem-fair.png" class="img-fluid" alt="image">
            </div>
          </b-col>
        </b-row>
    </b-card>
    <!-- *********upcoming fair & calender********* -->
      <itf-loading v-if="!dataLoad" :step="2" />
      <b-row v-else>
        <b-col sm="8">
          <b-card class="upcoming-fair">
            <div class="d-flex justifiy-center align-items-center flex-column">
              <div class="icon mb-2">
                <img class="img-fluid" src="@/assets/images/local-shop.png" alt="image">
              </div>
              <div v-if="dashboardData.latest_fair" class="content">
                <h5>{{ $i18n.locale === 'en' ? dashboardData.latest_fair.fair_name : dashboardData.latest_fair.fair_name_bn }}</h5>
                <h6 class="py-3"><strong>{{ $t('externalTradeFair.city_and_country') }}: </strong> {{ $i18n.locale === 'en' ? dashboardData.latest_fair.city : dashboardData.latest_fair.city_bn }}, {{ getCountryName(dashboardData.latest_fair.country_id) }}</h6>
                <p class="fair-date"><strong>{{ $t('externalTradeFair.duration') }}: </strong>{{ getDuration(dashboardData.latest_fair) }}</p>
              </div>
              <div v-else class="content">
                  <h5>{{$t('externalTradeFair.no_upcoming_fair')}}</h5>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col sm="4">
          <b-card>
            <!-- <img class="img-fluid" src="@/assets/images/calender.png" alt="image"> -->
            <div class="text-center">
              <b-calendar
                  v-model='selected_date'
                  @context="onContext"
                  :hide-header="hideHeader"
                  :show-decade-nav="showDecadeNav"
                  locale="en-US">
              </b-calendar>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div class="trade_fire_participant mb-4">
        <itf-loading v-if="!dataLoad" :step="3" />
        <b-row v-else>
          <b-col class="bdr" sm="3">
            <div class="info-card applicant">
              <div class="icon"><i class="ri-team-fill"></i></div>
              <div class="content">
                <p>{{ $t('tradeFairDashboard.total_applicant') }}</p>
                <h3>{{ $n(dashboardData.total_applicant, { useGrouping: false }) }}</h3>
              </div>
            </div>
          </b-col>
          <b-col class="bdr mt-3 mt-sm-0" sm="3">
            <div class="info-card approved">
              <div class="icon"><i class="ri-checkbox-fill"></i></div>
              <div class="content">
                <p>{{ $t('tradeFairDashboard.approved_application') }}</p>
                <h3>{{ $n(dashboardData.approved_application, { useGrouping: false }) }}</h3>
              </div>
            </div>
          </b-col>
          <b-col class="bdr" sm="3">
            <div class="info-card peinding">
              <div class="icon"><i class="ri-time-fill"></i></div>
              <div class="content">
                <p>{{ $t('tradeFairDashboard.pending_application') }}</p>
                <h3>{{ $n(dashboardData.pending_application, { useGrouping: false }) }}</h3>
              </div>
            </div>
          </b-col>
          <b-col class="bdr mt-3 mt-sm-0" sm="3">
            <div class="info-card reject">
              <div class="icon"><i class="ri-close-circle-fill"></i></div>
              <div class="content">
                <p>{{ $t('tradeFairDashboard.rejected_application') }}</p>
                <h3>{{ $n(dashboardData.rejected_application, { useGrouping: false }) }}</h3>
              </div>
            </div>
          </b-col>
          <b-col class="bdr mt-3" sm="3">
            <div class="info-card paid">
              <div class="icon"><i class="ri-user-follow-fill"></i></div>
              <div class="content">
                <p>{{ $t('tradeFairDashboard.paid_application') }}</p>
                <h3>{{ $n(dashboardData.paid_application, { useGrouping: false }) }}</h3>
              </div>
            </div>
          </b-col>
          <b-col class="bdr mt-3" sm="3">
            <div class="info-card unpaid">
              <div class="icon"><i class="ri-user-unfollow-fill"></i></div>
              <div class="content">
                <p>{{ $t('tradeFairDashboard.unpaid_application') }}</p>
                <h3>{{ $n(dashboardData.unpaid_application, { useGrouping: false }) }}</h3>
              </div>
            </div>
          </b-col>
          <b-col class="bdr mt-3" sm="3">
            <div class="info-card applicant">
              <div class="icon"><i class="ri-group-fill"></i></div>
              <div class="content">
                <p>{{ $t('tradeFairDashboard.attended_applicant') }}</p>
                <h3>{{ $n(dashboardData.attended_applicant, { useGrouping: false }) }}</h3>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import itfLoading from './loading/itf_loading'

export default {
  props: [],
  components: {
    itfLoading
  },
  data () {
    return {
      selected_date: '',
      context: null,
      showDecadeNav: false,
      hideHeader: true,
      search: {
          fiscal_year_id: 0,
          month: 0,
          fair_sector: 0
      },
      dashboardData: {
        approved_application: 0,
        attended_applicant: 0,
        attended_fair: 0,
        latest_fair: null,
        paid_application: 0,
        pending_application: 0,
        remaining_fair: 0,
        total_applicant: 0,
        total_circular: 0,
        unpaid_application: 0,
        upcoming_fair: 0
      },
      dataLoad: false
    }
  },
  computed: {
    fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    fairSectorList () {
        return this.$store.state.TradeFairService.commonObj.fairSectorList.filter(item => item.status === 1)
    },
    monthList () {
        return this.$store.state.commonObj.monthList.map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
        })
    }
  },
  created () {
    this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
  },
  watch: {
      'search.fiscal_year_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.loadData(newVal)
          }
      },
      'search.month': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.loadData(newVal)
          }
      },
      'search.fair_sector': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.loadData(newVal)
          }
      }
  },
  methods: {
    getDuration (item) {
        const startDate = item.date_from.split('-')
        const endDate = item.date_to.split('-')
        let startMonthId = ''
        let endMonthId = ''
        startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
        endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
        let customDate = ''
        if (startDate[1] === endDate[1]) {
            customDate = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
        } else {
            customDate = this.$n(startDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
        }
        return customDate
    },
    getCountryName (Id, needObj = false) {
        const country = this.$store.state.CommonService.commonObj.countryList.find(obj => obj.value === parseInt(Id))
        if (typeof country !== 'undefined') {
            if (needObj) {
                return country
            }
            return this.$i18n.locale === 'en' ? country.text_en : country.text_bn
        } else {
            return ''
        }
    },
    onContext (ctx) {
        this.context = ctx
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
        this.dataLoad = false
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const params = Object.assign({}, this.search)
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, 'dashboard/itf-dashboard', params)
        if (result.success) {
            this.dashboardData = result.data
            this.selected_date = this.dashboardData.latest_fair?.date_from
            this.dataLoad = true
        } else {
          this.dashboardData = {
            approved_application: 0,
            attended_applicant: 0,
            attended_fair: 0,
            latest_fair: null,
            paid_application: 0,
            pending_application: 0,
            remaining_fair: 0,
            total_applicant: 0,
            total_circular: 0,
            unpaid_application: 0,
            upcoming_fair: 0
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>

<style>
.b-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-help {
    display: none !important;
}
.b-calendar .b-calendar-inner .b-calendar-nav {
    display: none !important;
}

.trade_fire_wrapper .info-card {
  border-radius: 1rem;
  color: #214162;
  padding: 1rem;
  min-height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade_fire_wrapper .info-card .content p {
  font-size: 20px;
}

.trade_fire_wrapper .info-card.upcoming {
  color: #237dd3;
  background: #D2E8FD;
}

.trade_fire_wrapper .info-card.total {
  color: #875c1c;
  background: #FEF5CC;
}
.trade_fire_wrapper .info-card.atended {
  color: #04637e;
  background: #D0F2FC;
}

.trade_fire_wrapper .info-card.remaining {
  color: #f14b1f;
  background: #FEE7D7;
}

.trade_fire_wrapper .info-card img {
  height: 70px;
}
.upcoming-fair{
  background: url(../../../assets/images/fair-card-bg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
}
.upcoming-fair .icon img{
  width: 100px;
}
.upcoming-fair .content{
  padding: 2rem;
  width: 100%;
  color: #fff;
  text-align: center;
}
.trade_fire_participant .info-card {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}
.trade_fire_participant .info-card.applicant .icon{
  background: #48B5E4;
}
.trade_fire_participant .info-card.approved .icon{
  background: #21C228;
}
.trade_fire_participant .info-card.peinding .icon{
  background: #C96BE5;
}
.trade_fire_participant .info-card.reject .icon{
  background: #F8576A;
}
.trade_fire_participant .info-card.paid .icon{
  background: #6349F8;
}
.trade_fire_participant .info-card.unpaid .icon{
  background: #E79B55;
}
.trade_fire_participant .info-card .atended .icon{
  background: #55A6F8;
}
.trade_fire_participant .info-card .icon{
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  font-size: 1.5rem;
  margin-right: 0.6rem;
  margin-top: -3rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
  justify-content: center;
  align-items: center;
}
</style>
