<template>
  <div class="section-wrapper">
    <b-card class="ditf_search">
      <b-row>
          <b-col sm="3">
              <b-form-group label-for="year">
                <template v-slot:label>
                    {{$t('globalTrans.year')}}
                </template>
                <b-form-select plain v-model="search.year" :options="yearList" id="year">
                  <template v-slot:first>
                    <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-form-group label-for="fair_id">
                <template v-slot:label>
                    {{ $t('ditfConfig.fair_name') }}
                </template>
                <b-form-select plain v-model="search.fair_id" :options="fairTitleList" id="fair_id">
                    <template v-slot:first>
                        <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-form-group label-for="circular_type">
                <template v-slot:label>
                    {{$t('ditfConfig.circular') + ' ' + $t('ditfConfig.type')}}
                </template>
                <b-form-select plain v-model="search.circular_type" :options="circularTypeList" id="circular_type">
                    <template v-slot:first>
                      <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-form-group label-for="stall_cat_id">
                <template v-slot:label>
                    {{$t('externalTradeFair.stall') + ' ' + $t('ditfConfig.category')}}
                </template>
                <b-form-select plain v-model="search.stall_cat_id" :options="stallCategoryList" id="stall_cat_id">
                    <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.all')}}</b-form-select-option>
                    </template>
                </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-button size="sm" variant="primary" class="mb-2" @click="searchData">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
          </b-col>
        </b-row>
    </b-card>
    <!-- *********upcoming fair & calender********* -->
    <ditf-loading v-if="!dataLoad" />
    <b-row v-else>
      <b-col>
        <b-card class="upcoming-fair">
          <div class="d-flex justifiy-center align-items-center flex-column">
            <div class="icon mb-2">
              <img class="img-fluid" src="../../../assets/images/local-shop.png" alt="">
            </div>
            <div class="content">
              <h5>{{ currentLocale == 'en' ? dhakaFairList?.text_en : dhakaFairList?.text_bn }}</h5>
              <div class="d-flex align-items-center justify-content-center">
                <h6 class="py-3 mr-2"><strong>{{ $t('externalTradeFair.city_and_country') }}: </strong> {{ currentLocale === 'en' ? 'Dhaka, Bangladesh' : 'ঢাকা, বাংলাদেশ' }}</h6>
                <p class="fair-date"><strong>{{ $t('externalTradeFair.duration') }}: </strong>{{ dhakaFairList?.start_date ? getDuration(dhakaFairList.start_date, dhakaFairList.end_date) : '' }}</p>
              </div>
            </div>
          </div>
        </b-card>
        <div class="trade_fire_wrapper mb-4">
          <b-row>
            <b-col class="bdr" sm="4">
              <div class="info-card">
                <img src="../../../assets/images/shopping-mall.png" class="img-fluid" alt="">
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.total_stall') }}</p>
                  <h3>{{ $n(dashboardData.total_stall, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3 mt-sm-0" sm="4">
              <div class="info-card">
                <img src="../../../assets/images/food-store.png" class="img-fluid" alt="">
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.local_stall') }}</p>
                  <h3>{{ $n(dashboardData.local_stall, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr" sm="4">
              <div class="info-card">
                <img src="../../../assets/images/forign-shop.png" class="img-fluid" alt="">
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.foreign_stall') }}</p>
                  <h3>{{ $n(dashboardData.foreign_stall, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="ditf_participant mb-4">
          <b-row>
            <b-col class="bdr" sm="3">
              <div class="info-card applicant">
                <div class="icon"><i class="ri-team-fill"></i></div>
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.total_applicant') }}</p>
                  <h3>{{ $n(dashboardData.total_applicant, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3 mt-sm-0" sm="3">
              <div class="info-card approved">
                <div class="icon"><i class="ri-checkbox-fill"></i></div>
                <div class="content">
                  <!-- <p>{{ $t('tradeFairDashboard.approved_application') }}</p> -->
                  <p>{{ $i18n.locale === 'en' ? 'Recommended Application' : 'প্রস্তাবিত আবেদন' }}</p>
                  <h3>{{ $n(dashboardData.recommended_application, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr" sm="3">
              <div class="info-card peinding">
                <div class="icon"><i class="ri-time-fill"></i></div>
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.pending_application') }}</p>
                  <h3>{{ $n(dashboardData.pending_application, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3 mt-sm-0" sm="3">
              <div class="info-card reject">
                <div class="icon"><i class="ri-close-circle-fill"></i></div>
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.rejected_application') }}</p>
                  <h3>{{ $n(dashboardData.refunded_application, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3" sm="3">
              <div class="info-card paid">
                <div class="icon"><i class="ri-user-follow-fill"></i></div>
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.paid_application') + '/' + $t('externalTradeFair.stall') }}</p>
                  <h3>{{ $n(dashboardData.paid_application, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3" sm="3">
              <div class="info-card unpaid">
                <div class="icon"><i class="ri-user-unfollow-fill"></i></div>
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.unpaid_application') + '/' + $t('externalTradeFair.stall') }}</p>
                  <h3>{{ $n(dashboardData.unpaid_application, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3" sm="3">
              <div class="info-card applicant">
                <div class="icon"><i class="ri-group-fill"></i></div>
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.attended_applicant') }}</p>
                  <h3>{{ $n(dashboardData.attended_applicant, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3" sm="3">
              <div class="info-card foreign">
                <div class="icon"><i class="ri-global-fill"></i></div>
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.foreign_applicant') }}</p>
                  <h3>{{ $n(dashboardData.foreign_applicant, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col sm="8">
            <b-card>
              <div class="text-dark">
                <h6 class="card_title">{{ $i18n.locale === 'en' ? 'Year-wise Fair Participants Status' : 'মেলায় অংশগ্রহণকারীদের বার্ষিক অবস্থা' }}</h6>
                <apexchart type="bar" height="240" :options="barOptions" :series="barSeries"></apexchart>
                <!-- <div class="text-center">Applicant</div> -->
            </div>
              <!-- <img class="img-fluid" src="../../../assets/images/chart-3.png" alt=""> -->
            </b-card>
          </b-col>
          <b-col sm="4">
            <b-card>
              <!-- <img class="img-fluid" src="../../../assets/images/Pie Chart.png" alt=""> -->
              <div class="">
                <h6 class="card_title">{{ $i18n.locale === 'en' ? 'Local - Foreign Applicants' : 'স্থানীয় - বিদেশি আবেদনকারী' }}</h6>
                <apexchart class="chart-1 text-center" height="260" type="pie" :options="localForeignLabels" :series="localForeignSeries()"></apexchart>
            </div>
            </b-card>
          </b-col>
        </b-row>
        <!-- <div class="ditf-info-table">
          <b-row>
            <b-col>
              <b-card header="(ক) প্যাভিলিয়ন/ স্টল/ রেস্টুরেন্টের শ্রেণী, প্রসেসিং ফি, রয়্যাল্টি/ ভাড়ার হার (স্থানীয়)">
                <div class="table-wrapper table-responsive">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">ক্রঃ</th>
                        <th scope="col">প্যাভিলিয়ন, স্টল, রেস্টুরেন্ট <small>(ক্যাটাগরি/ প্রকৃতি/ নাম)</small></th>
                        <th scope="col">সাইজ</th>
                        <th scope="col">আবেদন পত্র প্রসেসিং ফি</th>
                        <th scope="col">ফ্রোর মুল্য</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">১</th>
                        <td>সাউথ প্রিমিয়ার প্যাভিলিয়ন (SPP) ক্যাটাগরি- এ</td>
                        <td>(৬৫ x  ৩৬ ) ইন্সি</td>
                        <td>টাঃ ১৮,০০০/-</td>
                        <td>টাঃ ১৭,৫০,০০০/-</td>
                      </tr>
                      <tr>
                        <th scope="row">২</th>
                        <td>সাউথ প্রিমিয়ার প্যাভিলিয়ন (SPP) ক্যাটাগরি- এ</td>
                        <td>(৬৫ x  ৩৬ ) ইন্সি</td>
                        <td>টাঃ ১৮,০০০/-</td>
                        <td>টাঃ ১৭,৫০,০০০/-</td>
                      </tr>
                      <tr>
                        <th scope="row">৩</th>
                        <td>সাউথ প্রিমিয়ার প্যাভিলিয়ন (SPP) ক্যাটাগরি- এ</td>
                        <td>(৬৫ x  ৩৬ ) ইন্সি</td>
                        <td>টাঃ ১৮,০০০/-</td>
                        <td>টাঃ ১৭,৫০,০০০/-</td>
                      </tr>
                      <tr>
                        <th scope="row">৪</th>
                        <td>সাউথ প্রিমিয়ার প্যাভিলিয়ন (SPP) ক্যাটাগরি- এ</td>
                        <td>(৬৫ x  ৩৬ ) ইন্সি</td>
                        <td>টাঃ ১৮,০০০/-</td>
                        <td>টাঃ ১৭,৫০,০০০/-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
  import ditfLoading from './loading/ditf_loading'

  export default {
    props: [],
    components: {
      ditfLoading
    },
    data () {
      return {
        dashboardData: {
          attended_applicant: 0,
          foreign_applicant: 0,
          foreign_stall: 0,
          local_stall: 0,
          paid_application: 0,
          participants: [],
          pending_application: 0,
          recommended_application: 0,
          refunded_application: 0,
          total_applicant: 0,
          total_stall: 0,
          unpaid_application: 0
        },
        fairTitleList: [],
        stallCategoryList: [],
        search: {
            year: 0,
            fair_id: 0,
            circular_type: 0,
            stall_cat_id: 0
        },
        dataLoad: false,
        localForeignData: [],
        firstLabel: ['Local', 'Foreign'],
        firstLabelBn: ['স্থানীয়', 'বিদেশী'],
        applicantData: []
      }
    },
    computed: {
      barSeries () {
          return [{
              name: this.$i18n.locale === 'bn' ? 'মোট আবেদনকারী' : 'Total Applicant',
              data: this.applicantData
          }]
      },
      barOptions () {
        return {
            chart: {
                type: 'bar',
                height: 240
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['transparent']
            },
            xaxis: {
                title: {
                  text: this.$t('globalTrans.year')
                },
                type: 'year',
                categories: this.getYearList()
            },
            yaxis: {
                title: {
                  text: this.$t('globalTrans.number')
                }
            },
            fill: {
                opacity: 1
            }
        }
      },
      localForeignLabels () {
        return {
          chart: {
            height: 260,
            type: 'pie'
          },
          labels: this.$i18n.locale === 'bn' ? this.firstLabelBn : this.firstLabel,
          colors: ['#02ADEE', '#ED008C'],
          legend: {
            position: 'bottom'
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      },
      customYearList () {
          const min = new Date().getFullYear()
          const oldMax = min - 4
          const newMax = min + 0
          const newYears = []
          const oldYears = []
          for (let i = oldMax; i < min; i++) {
              oldYears.push(i)
          }
          for (let j = min; j <= newMax; j++) {
              newYears.push(j)
          }
          const yearList = [...oldYears, ...newYears]
          return yearList
      },
      dhakaFairList () {
        return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(el => el.year === this.search.year)
      },
      currentLocale () {
          return this.$i18n.locale
      },
      yearList () {
          return this.$store.state.commonObj.yearList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item, text: this.$n(item, { useGrouping: false }) }
              } else {
                  return { value: item, text: item }
              }
          })
      },
      circularTypeList () {
          return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
              return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
          })
      }
    },
    created () {
      this.search.year = new Date().getFullYear()
      this.search.circular_type = 1
      this.loadData()
    },
    watch: {
      'search.year': function (newVal) {
          this.fairTitleList = this.dhakaFair()
      },
      'search.circular_type': function (newVal) {
          this.stallCategoryList = this.getStallCategoryList()
      },
      currentLocale: function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.fairTitleList = this.dhakaFair()
              this.stallCategoryList = this.getStallCategoryList()
          }
      }
    },
    methods: {
      getYearList () {
          const year = []
          this.customYearList.map(el => {
            if (this.$i18n.locale === 'bn') {
              const bn = this.$n(el, { useGrouping: false })
              year.push(bn)
            } else {
              year.push(el)
            }
          })
        return year
      },
      localForeignSeries () {
        return this.localForeignData
      },
      searchData () {
        this.loadData()
      },
      getDuration (dateFrom, dateTo) {
          const startDate = dateFrom.split('-')
          const endDate = dateTo.split('-')
          let startMonthId = ''
          let endMonthId = ''
          startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
          endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
          let customDate = ''
          if (startDate[1] === endDate[1]) {
              customDate = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
          } else {
              customDate = this.$n(startDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
          }
          return customDate
      },
      async loadData () {
          this.dataLoad = false
          this.applicantData = []
          this.localForeignData = []
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          const params = Object.assign({}, this.search)
          const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, 'dashboard/ditf-dashboard', params)
          if (result.success) {
              this.dashboardData = result.data
              this.localForeignData.push(this.dashboardData.total_applicant - this.dashboardData.foreign_applicant)
              this.localForeignData.push(this.dashboardData.foreign_applicant)
              if (result.data.participants.length) {
                this.customYearList.forEach(el => {
                  const Obj = result.data.participants.find(item => parseInt(item.year) === el)
                  if (typeof Obj !== 'undefined') {
                    this.applicantData.push(Obj.number)
                  } else {
                    this.applicantData.push(0)
                  }
                })
              }

              this.dataLoad = true
          } else {
            this.dashboardData = {
              attended_applicant: 0,
              foreign_applicant: 0,
              foreign_stall: 0,
              local_stall: 0,
              paid_application: 0,
              participants: [],
              pending_application: 0,
              recommended_application: 0,
              refunded_application: 0,
              total_applicant: 0,
              total_stall: 0,
              unpaid_application: 0
            }
            this.dataLoad = true
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      dhakaFair () {
          this.search.fair_id = 0
          return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
              if (item !== '') {
                  this.search.fair_id = item.value
              }
              return { value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en }
          })
      },
      getStallCategoryList () {
          return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.filter(item => item.circular_type === this.search.circular_type).map(item => {
              return { value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en }
          })
      }
    }
  }
</script>
<style scoped>
.trade_fire_wrapper {
  border-radius: .2rem;
  background: #55A6F8 url(../../../assets/images/card-bg2.png);
  background-size: cover;
  background-position: left;
}
.trade_fire_wrapper .info-card {
  border-radius: 4px;
  color: #fff;
  padding: 1rem;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trade_fire_wrapper .info-card .content{
  text-align: center;
}
.trade_fire_wrapper .info-card .content p {
  font-size: 20px;
}
.trade_fire_wrapper .info-card img {
  height: 50px;
}
.trade_fire_wrapper .info-card.upcoming {
  background: linear-gradient(90deg, #dc3545 0%, rgb(252 80 42) 100%);
}

.trade_fire_wrapper .info-card.total {
  background: linear-gradient(90deg, #7d1dff 0%, #9448fa 100%);
}

.trade_fire_wrapper .info-card.atended {
  background: linear-gradient(90deg, #e39630 0%, #eecc61 100%);;
}

.upcoming-fair {
  background-image: url(../../../assets/images/bg-3.png), linear-gradient(90deg, #7716fe 0%, #9d56fa 100%);
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
}

.upcoming-fair .icon img {
  width: 100px;
}

.upcoming-fair .content {
  padding: 1rem;
  width: 100%;
  color: #fff;
  text-align: center;
}

.ditf_participant .info-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ditf_participant .info-card .icon {
  padding-right: 1rem;
  color: #fff;
  font-size: 1.5rem;
  margin-right: .6rem;
}

.ditf_participant .info-card.applicant {
  background: linear-gradient(90deg, #520979 20%, #f9efff 23%);
}

.ditf_participant .info-card.approved {
  background: rgb(33, 194, 40);
  background: linear-gradient(90deg, #21c228 20%, #effff0 23%);
}

.ditf_participant .info-card.peinding {
  background: #C96BE5;
  background: linear-gradient(90deg, #C96BE5 20%, #faebff 23%);
}

.ditf_participant .info-card.reject {
  background: #F8576A;
  background: linear-gradient(90deg, #F8576A 20%, #ffeef0 23%);
}

.ditf_participant .info-card.paid {
  background: #6349F8;
  background: linear-gradient(90deg, #6349F8 20%, #efecff 23%);
}

.ditf_participant .info-card.unpaid {
  background: #E79B55;
  background: linear-gradient(90deg, #E79B55 20%, #fff7ef 23%);
}

.ditf_participant .info-card.atended {
  background: #55A6F8;
  background: linear-gradient(90deg, #55A6F8 20%, #eaf5ff 23%);
}

.ditf_participant .info-card.foreign {
  background: #2943d6;
  background: linear-gradient(90deg, #2943d6 20%, #e9ecff 23%);
}
</style>
