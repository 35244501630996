<template>
  <div class="section-wrapper">
    <bread-cumb />
    <!-- <b-card class="trade_fire_wrapper_common"> -->
    <b-row>
        <b-col class="text-center mb-3">
            <b-form-radio-group
                v-model="dashboard_type"
                :options="typeList"
                value-field="value"
                text-field="text"
            ></b-form-radio-group>
        </b-col>
    </b-row>
    <!-- </b-card> -->
    <b-row>
        <b-col sm="12">
            <component :is='comp' :ref="comp" />
        </b-col>
    </b-row>
  </div>
</template>
<script>
import ITF from './ITF'
import DITF from './DITF'
import BreadCumb from '@/components/BreadCumb.vue'
export default {
  props: [],
  components: {
    BreadCumb, ITF, DITF
  },
  data () {
    return {
        comp: 'ITF',
        dashboard_type: 1,
        loading: false
    }
  },
  watch: {
    dashboard_type: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (parseInt(newVal) === 1) {
                this.comp = 'ITF'
            } else {
                this.comp = 'DITF'
            }
        }
    }
  },
  computed: {
    typeList () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'International Trade Fair' : 'আন্তর্জাতিক বাণিজ্য মেলা' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Dhaka International Trade Fair' : 'ঢাকা আন্তর্জাতিক বাণিজ্য মেলা' }
        ]
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
.trade_fire_wrapper_common {
  border-radius: 1rem;
  text-align: center;
  margin: auto;
  color: #214162;
  /* padding: 0.2rem; */
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
</style>
