<template>
    <b-row>
        <b-col v-if="step == 1">
            <b-row>
                <b-col sm="12" md="3">
                    <b-skeleton height="130px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3">
                    <b-skeleton height="130px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3">
                    <b-skeleton height="130px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3">
                    <b-skeleton height="130px" animation="wave" width="100%"></b-skeleton>
                </b-col>
            </b-row>
        </b-col>
        <b-col v-if="step == 2">
            <b-row>
                <b-col sm="12" md="8">
                    <b-skeleton height="300px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="4">
                    <b-skeleton height="300px" animation="wave" width="100%"></b-skeleton>
                </b-col>
            </b-row>
        </b-col>
        <b-col v-if="step == 3">
            <b-row>
                <b-col sm="12" md="3" class="mt-3">
                    <b-skeleton height="100px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3" class="mt-3">
                    <b-skeleton height="100px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3" class="mt-3">
                    <b-skeleton height="100px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3" class="mt-3">
                    <b-skeleton height="100px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3" class="mt-3">
                    <b-skeleton height="100px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3" class="mt-3">
                    <b-skeleton height="100px" animation="wave" width="100%"></b-skeleton>
                </b-col>
                <b-col sm="12" md="3" class="mt-3">
                    <b-skeleton height="100px" animation="wave" width="100%"></b-skeleton>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>
<script>
export default {
  props: ['step']
}
</script>
